import {LitElement, html, css} from 'lit-element';
import '@banno/platform-ux-shared/components/polymer3/jha/progress/jha-progress.js';
import '../auth/jha-auth.js';
import {calculateDeviceId} from '../../js/device-fingerprint.js';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
import router from '@banno/platform-ux-shared/services/router-jh-wcr.js';
import RoutePaths from '../../js/routing/paths.js';
import JhaFetch from '@banno/platform-ux-shared/services/jha-fetch.js';
import UserAccessController from '@banno/platform-ux-shared/controllers/user-access-controller.js';
import TwoFactorController from '@banno/platform-ux-shared/controllers/two-factor-controller.js';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import AuthController from '@banno/platform-ux-shared/controllers/auth-controller.js';

const styles = css`
  :host {
    display: block;
  }
  header {
    text-align: center;
    padding: 32px;
    border-bottom: 1px solid var(--jha-border-color);
  }
  h2 {
    color: var(--jha-text-dark);
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  article {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
  jha-progress {
    margin: 32px auto;
    display: block;
    width: 32px;
    height: 32px;
  }
  @media (min-width: 740px) {
    h2 {
      font-size: 18px;
      font-weight: 400;
    }
  }
`;

/** @polymer */
class XperienceTwoElement extends RouterMixin(LitElement) {
  static get is() {
    return 'xperience-two';
  }

  static get styles() {
    return [styles];
  }

  static get properties() {
    return {
      loginResponseStatus: {
        type: Number,
      },
      institutions: {
        type: Array,
      },
      redirectPath: {
        type: String,
      },
      loggedOutDueToRollingTimeout: {
        type: Boolean,
      },
      loggedOutDueToAbsoluteTimeout: {
        type: Boolean,
      },
      errorBody: {
        type: String,
      },
      loginLockedOut: {
        type: Boolean,
      },
      loading: {
        type: Boolean,
      },
      email: {
        type: String,
      },
      openView: {
        type: String,
      },
      whitelistURL: {
        type: String,
      },
      whitelistLocalURL: {
        type: String,
      },
      highRiskEnrolled: {
        type: Boolean,
      },
      highRiskRequired: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.loginResponseStatus = 200;
    this.redirectPath = '';
    this.loggedOutDueToRollingTimeout = false;
    this.loggedOutDueToAbsoluteTimeout = false;
    this.loginLockedOut = false;
    this.loading = false;
    this.openView = 'login';
    this.whitelistURL = 'banno.com';
    this.whitelistLocalURL = 'localhost';
    this.deviceHash;
  }

  async routeEnter(currentNode, nextNodeIfExists, routeId, context, next) {
    // eslint-disable-next-line camelcase
    const redirectUri = this._verifyRedirect(context.query.get('redirect_to'));
    if (JhaFetch.getCookie('XSRF-TOKEN')) {
      let redirectTo = '';
      if (redirectUri) { // eslint-disable-line camelcase
        redirectTo = `?redirect_to=${encodeURIComponent(redirectUri)}`; // eslint-disable-line camelcase
      }
      location.href = `${RoutePaths.LOGIN_LOGOUT}${redirectTo}`;
      return next(false);
    }
    if (context.query.get('rolling')) {
      this.loggedOutDueToRollingTimeout = true;
    }
    if (context.query.get('absolute')) {
      this.loggedOutDueToAbsoluteTimeout = true;
    }
    await super.routeEnter(currentNode, nextNodeIfExists, routeId, context, next);
    if (redirectUri) { // eslint-disable-line camelcase
      this.redirectPath = redirectUri; // eslint-disable-line camelcase
    }
  }

  connectedCallback() {
    super.connectedCallback();
    window.localStorage.setItem(`isXp3`, true);
    this.loading = true;
    this.deviceHash = calculateDeviceId();
    console.log(jackHenry);
    jackHenry.xperience.messageBus.onReady(() => {
      if (jackHenry.xperience.isFrameworkHosted) {
        // Must connect with message bus before sending any messages
        jackHenry.xperience.messageBus.connect();
        if (jackHenry.xperience.messageBus.isConnected) {
          const currentUserRequest = new jackHenry.enterprise.businessObjects.UsrSecTokenRetrv();
          currentUserRequest.RefreshFromServer = 'false';
          if (currentUserRequest.JESMsgRqHdr == null) {
            const header = new jackHenry.enterprise.businessObjects.JESMsgRqHdr_CType();
            header.JESHdr = new jackHenry.enterprise.businessObjects.JESHdr_CType();
            header.JESHdr.ConsumerProd = 'BannoWeb';
            currentUserRequest.JESMsgRqHdr = header;
          }
          const responseCallback = jackHenry.xperience.messageBus
              .send(currentUserRequest)
              .registerResponseHandler((context) => {
                if (!context.isValid()) {
                } else {
                  const response = context.messageToJson();
                  this.login(response.UsrSecTokenRec);
                }
              });
        }
      } else {
        this._loginError({
          response: 'XperienceFrameworkNotHosted',
          statusCode: 400,
        });
      }
    }, 1500);
    super.connectedCallback();
  }

  _verifyRedirect(redirectTo) {
    if (!redirectTo) {
      return '';
    }

    const redirectURL = new URL(redirectTo, window.location.href);
    const bannoAppURL = new URL(window.location.href);

    const sameHost = redirectURL.host === bannoAppURL.host;
    const validProtocol = redirectURL.protocol === 'https:';
    const whitelisted = redirectURL.hostname.endsWith(this.whitelistURL) || redirectURL.hostname === this.whitelistLocalURL;

    return sameHost && validProtocol && whitelisted ? redirectTo : '';
  }


  _storeSessionDetails() {
    store.institutions = this.institutions;
    store.user = this.user;
  }

  _getSelectedInstitutionId() {
    if (!this.institutions) {
      return '';
    }
    if (this.institutions.length === 1) {
      this._setSelectedInstitutionInStore(this.institutions[0]);
      return this.institutions[0].institution.shortId.toString();
    }
    const selectedInstitution = this.institutions.find((instData) => instData.selected);
    if (selectedInstitution) {
      return selectedInstitution.institution.shortId.toString();
    }
  }

  async _setSelectedInstitutionInStore(selectedInstitution) {
    const {shortId} = selectedInstitution.institution;
    store.selectedInstitution = shortId;
  }

  async _profileIncomplete() {
    if (!this.user.internal) {
      if (!this.user.firstName || !this.user.lastName) {
        return true;
      } else if (!this.user.bio || !this.user.location) {
        const mailboxesKey = this.institutions.map((institution) => ({
          institutionId: institution.institution.institutionId,
          privilege: 'read',
          application: 'mailboxes',
          resource: 'conversations',
          resourceId: '*',
          property: '*',
          key: '1'
        }));
        const mailboxPermission = await UserAccessController.validatePermissions(mailboxesKey);
        return mailboxPermission.some((permission) => permission.result === 'allowed');
      }
      return false;
    }
    return false;
  }

  async _redirect() {
    const selectedInstitutionShortId = this._getSelectedInstitutionId();
    if (await this._profileIncomplete()) {
      const profileURL = '/a/settings/profile';
      router.go(profileURL + (this.redirectPath ? '?redirect_to=' + this.redirectPath : ''));
    } else {
      const redirectTo = this.redirectPath ? this.redirectPath.trim() : '/a/';
      if (redirectTo === '/a/') {
        if (selectedInstitutionShortId) {
          router.go(`/a/${selectedInstitutionShortId}`);
          return;
        }
        if (this.institutions.length > 1) {
          router.go(RoutePaths.INSTITUTIONS);
          return;
        }
        router.go(RoutePaths.INSTITUTIONS);
      } else {
        router.go(redirectTo);
      }
    }
  }

  _changeView(view) {
    this.openView = view;
  }

  async _loginSuccess(user) {
    const session = await AuthController.getSession();
    const highRisk = session.highRisk;
    this.user = session.user;
    this.institutions = session.institutions;
    this.highRiskEnrolled = highRisk.enrolled;
    this.highRiskRequired = highRisk.required;
    if (highRisk.enrolled && !highRisk.verified) {
      TwoFactorController.getAuthSession({userId: this.user.userId}).then((session) => {
        this.highRiskEnrolled = session.enrollment.confirmed;
        if (this.highRiskEnrolled && !session.verified) {
          this.userPhone = session.enrollment.phone;
          this._changeView('auth');
        } else if (this.highRiskEnrolled && session.verified) {
          this._storeSessionDetails();
          this._redirect();
        }
      });
    } else if (highRisk.enabled && !highRisk.verified) {
      this._changeView('auth');
    } else {
      this._storeSessionDetails();
      this._redirect();
    }
  }

  _loginError(error) {
    console.log(error);
    if (!error) {
      return;
    }
    this.loginResponseStatus = error.statusCode;
    if (error) {
      this.errorBody = error.response;
    }
    this._endLoading();
    if (error.response && error.response.lockedOut) {
      this.loginLockedOut = true;
    }
  }

  login(tokenRecord) {
    const SAMLToken = tokenRecord.samlAssertion;
    this.email = tokenRecord.EmailAddr;
    this.deviceHash.then((deviceId) => {
      this.loginResponseStatus = -1;
      AuthController.xplogin(SAMLToken, deviceId)
          .then((user) => this._loginSuccess(user))
          .catch((error) => this._loginError(error));
    });
  }

  _endLoading() {
    if (this.loginResponseStatus !== undefined && (this.errorBody || this.loginResponseStatus >= 400)) {
      this.loading = false;
    }
  }

  _authVerified() {
    this._storeSessionDetails();
    this._redirect();
  }

  _close(e) {
    this._redirect();
  }

  _computeErrorHeading(responseStatus, errorBody) {
    return this._computeError(responseStatus, errorBody).heading;
  }

  _computeErrorMessage(responseStatus, errorBody) {
    return this._computeError(responseStatus, errorBody).message;
  }

  _computeError(responseStatus, errorBody) {
    if (responseStatus === undefined || responseStatus <= 300) {
      return {heading: '', message: ''};
    }

    const heading = 'Oops, something went wrong';
    const message =  'We were unable to authenticate your login. Please close the tab and try again.';
    if (responseStatus >= 500) {
      return {
        heading,
        message
      };
    }

    switch ((errorBody || '').trim()) {
      case 'EnterpriseUserNotFound':
        return {
          heading: 'Request access from your administrator',
          message: `You've logged in successfully, however you need additional permissions to have access. ` +
              `Please contact your administrator and request updated permissions. Once updated, try again.`
        };
      case 'NoXperienceEnabledInstitutions':
        return {
          heading: 'Xperience logins unavailable',
          message: 'Your institution is not yet configured to support logins from Xperience. ' +
              'Please have your administrator contact Banno to configure your institution.'
        };
      case 'EmailMissingFailure':
        return {
          heading: 'Xperience emailaddress attribute is missing',
          message: 'Your email address is not configured in Xperience. Missing the the emailaddress attribute. ' +
              'Please contact your I.T. Administrator and request for them to set the emailaddress attribute on your Active Directory or LDAP account. Once updated, please try again.'
        };
      case 'XperienceFrameworkNotHosted':
        return {
          heading: 'Xperience cannot reauthenticate this session',
          message: 'Your Banno session has timed out and was unable to reauthenticate you at this time. Please close this instance of Banno and try again.',
        };
      default:
        break;
    }

    return {
      heading,
      message
    };
  }

  render() {
    return html`
      ${this.openView === 'login' ? html`
        ${this.loading ? html`
          <article>
            <jha-progress></jha-progress>
          </article>
        ` : html`
          <header>
            <h2>${this._computeErrorHeading(this.loginResponseStatus, this.errorBody)}</h2>
          </header>
          <article>
            ${this._computeErrorMessage(this.loginResponseStatus, this.errorBody)}
          </article>
        `}
      ` : null}
      ${this.openView === 'auth' ? html`
        <jha-auth @close=${this._close} user-id=${this.user.userId} email=${this.email} @auth-verified=${this._authVerified} phone=${this.userPhone} .isOpen=${this.openView === 'auth'} .isEnrolled=${this.highRiskEnrolled} .authRequired=${this.highRiskRequired}></jha-auth>
      ` : null}
    `;
  }
}

customElements.define(XperienceTwoElement.is, XperienceTwoElement);
